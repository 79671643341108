<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Sửa dân ca</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminFolkList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách dân ca
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên dân ca (*)</label>
              <input v-model="folk.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Lời dân ca <i class="far fa-question-circle"
                                                                       :uk-tooltip="'Chỉ ghi lời dân ca, không ghi tên dân ca, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="folk.content"/>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Soạn lời <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn soạn giả trong danh sách'"></i></label>
              <multiselect
                  v-model="recomposers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchRecomposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addRecomposerToFolk" v-model="recomposer_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm soạn giả mới">
                    <button @click="addRecomposerToFolk" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Sáng tác (tân nhạc) <i class="far fa-question-circle"
                                                                  :uk-tooltip="'Chọn tác giả nhạc trong danh sách'"></i></label>
              <multiselect
                  v-model="composers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchComposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addComposerToFolk" v-model="composer_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm nhạc sĩ mới">
                    <button @click="addComposerToFolk" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Trình bày <i class="far fa-question-circle"
                                                        :uk-tooltip="'Chọn người trình bày trong danh sách'"></i></label>
              <multiselect
                  v-model="artists"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addArtistToFolk" v-model="artist_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm nghệ sĩ mới">
                    <button @click="addArtistToFolk" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Thể loại <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn thể loại trong danh sách'"></i></label>
              <multiselect
                  v-model="fcats"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchFcats"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Làn điệu <i class="far fa-question-circle"
                                                       :uk-tooltip="'Chọn làn điệu trong danh sách'"></i></label>
              <multiselect
                  v-model="melodies"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchMelodies"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label for="year" class="font-semibold">Năm sáng tác</label>
              <input v-model="folk.year" class="shadow-none with-border" id="year" type="text" autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="folk.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="subtitle" class="font-semibold">Ghi chú</label>
              <input v-model="folk.subtitle" class="shadow-none with-border" id="subtitle" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-on:uploadDone="handleUploadDone" :musicTypeProp="musicType" :playerOptionsProp="playerOptions"
                    v-model:objectIdProp="folk.file_id"/>
        </div>

        <div class="mt-4" v-if="folk.file && folk.file.type === 'video'">
          <label class="font-semibold">Kiểu hiển thị nhạc</label>
          <select v-model="folk.file_type">
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="folk.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="updateFolk">
              Cập nhật
            </button>

            <router-link v-if="folk.id"
                         :to="{name: 'SongDetail', params: {id: folk.id, slug: folk.slug, prefix: 'dan-ca'}}"
                         class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem
            </router-link>

            <a class="text-white hover:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
               href="#" id="deleteArtistBtn" uk-toggle="target: #deleteFolkModal">
              Xóa
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="deleteFolkModal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Xóa dân ca</h3>
          <button id="deleteFolkModalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                  type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <p>Bạn có chắc chắn muốn xóa dân ca <span class="font-semibold">{{ folk.title }}</span>?</p>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="deleteFolk"
             class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Xóa dân ca</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageSong, changePageTitle, closeModal} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminFolkEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      id: null,
      musicType: "audio",
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: []
      },
      image: null,
      folk: {
        id: 0,
        title: "",
        content: "",
        subtitle: "",
        year: "",
        record_year: "",
        file_id: null,
        file_type: "",
        image_id: null,
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      composers: [],
      recomposers: [],
      artists: [],
      melodies: [],
      fcats: [],
      composer_title: "",
      artist_title: "",
      recomposer_title: ""
    }
  },
  methods: {
    loadFolk() {
      let query = `query($id: ID!) {
        folk(id: $id) {
          id
          title
          slug
          content
          subtitle
          year
          record_year
          file_id
          file_type
          image_id
          file {
            id
            audio
            video
            audio_url
            video_url
            type
          }
          thumbnail {
            id
            url
          }
          uploader {
            id
            username
            avatar {
              url
            }
          }
          composers(first: 100, orderBy: [{column: "folk_composer.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          recomposers(first: 100, orderBy: [{column: "folk_recomposer.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          artists(first: 100, orderBy: [{column: "folk_artist.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          melodies(first: 100) {
            data {
              id
              title
            }
          }
          fcats(first: 100) {
            data {
              id
              title
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.folk) {
              this.folk = data.data.folk;
              this.uploader = this.folk.uploader;

              if (this.folk.file) {
                this.musicType = this.folk.file.type;
                let src = this.folk.file.type === "audio" ? this.folk.file.audio_url : this.folk.file.video_url;
                let type = this.folk.file.type === "audio" ? "audio/mp3" : "video/mp4";
                this.playerOptions.sources[0] = {
                  src: src,
                  type: type
                };
              }

              if (this.folk.thumbnail) {
                this.image = this.folk.thumbnail.url;
              }

              if (this.folk.composers && this.folk.composers.data) {
                this.composers = this.folk.composers.data;
              }
              if (this.folk.recomposers && this.folk.recomposers.data) {
                this.recomposers = this.folk.recomposers.data;
              }
              if (this.folk.artists && this.folk.artists.data) {
                this.artists = this.folk.artists.data;
              }
              if (this.folk.melodies && this.folk.melodies.data) {
                this.melodies = this.folk.melodies.data;
              }
              if (this.folk.fcats && this.folk.fcats.data) {
                this.fcats = this.folk.fcats.data;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateFolk() {
      if (!this.folk.title) {
        this.$toast.error("Bạn chưa nhập tên dân ca");
        return;
      }

      if (!this.folk.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $subtitle: String, $year: String, $record_year: String, $file_type: String, $file_id: ID, $image_id: ID, $uploader_id: ID, $sync_folk_composer: [SyncFolkComposer], $sync_folk_recomposer: [SyncFolkRecomposer], $sync_folk_artist: [SyncFolkArtist], $sync_folk_melody: [SyncFolkMelody], $sync_folk_fcat: [SyncFolkFcat]) {
        updateFolk(input: {
          id: $id,
          title: $title,
          content: $content
          subtitle: $subtitle
          year: $year
          record_year: $record_year
          file_type: $file_type
          file_id: $file_id
          image_id: $image_id
          uploader_id: $uploader_id
          composers: {
            sync: $sync_folk_composer
          }
          recomposers: {
            sync: $sync_folk_recomposer
          }
          artists: {
            sync: $sync_folk_artist
          }
          melodies: {
            sync: $sync_folk_melody
          }
          fcats: {
            sync: $sync_folk_fcat
          }
        }) {
          id
        }
      }`;

      let sync_folk_composer = [];
      let sync_folk_recomposer = [];
      let sync_folk_artist = [];
      let sync_folk_melody = [];
      let sync_folk_fcat = [];

      this.composers.map((item, index) => {
        sync_folk_composer.push({id: item.id, order: index});
      });
      this.recomposers.map((item, index) => {
        sync_folk_recomposer.push({id: item.id, order: index});
      });
      this.artists.map((item, index) => {
        sync_folk_artist.push({id: item.id, order: index});
      });
      this.melodies.map((item) => {
        sync_folk_melody.push({id: item.id});
      });
      this.fcats.map((item) => {
        sync_folk_fcat.push({id: item.id});
      });

      ApiService.graphql(query, {
        id: this.folk.id,
        title: this.folk.title,
        content: this.folk.content,
        subtitle: this.folk.subtitle,
        year: this.folk.year,
        record_year: this.folk.record_year,
        file_type: this.folk.file_type,
        file_id: this.folk.file_id,
        image_id: this.folk.image_id,
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_folk_composer: sync_folk_composer,
        sync_folk_recomposer: sync_folk_recomposer,
        sync_folk_artist: sync_folk_artist,
        sync_folk_melody: sync_folk_melody,
        sync_folk_fcat: sync_folk_fcat
      })
          .then(({data}) => {
            if (data.data && data.data.updateFolk) {
              this.$toast.success("Cập nhật dân ca thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    addComposerToFolk() {
      if (!this.composer_title) {
        this.$toast.error("Bạn chưa nhập tên nhạc sĩ");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.composer_title,
        people_type: "composer",
        song_type: "folk",
        song_id: this.folk.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm nhạc sĩ thành công");
          this.composer_title = "";
          this.loadFolk();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    addRecomposerToFolk() {
      if (!this.recomposer_title) {
        this.$toast.error("Bạn chưa nhập tên soạn giả");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.recomposer_title,
        people_type: "recomposer",
        song_type: "folk",
        song_id: this.folk.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm soạn giả thành công");
          this.recomposer_title = "";
          this.loadFolk();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    addArtistToFolk() {
      if (!this.artist_title) {
        this.$toast.error("Bạn chưa nhập tên nghệ sĩ");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.artist_title,
        people_type: "artist",
        song_type: "folk",
        song_id: this.folk.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm nghệ sĩ thành công");
          this.artist_title = "";
          this.loadFolk();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    deleteFolk() {
      let query = `mutation($id: ID!) {
        deleteFolk(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.folk.id})
          .then(({data}) => {
            if (data.data && data.data.deleteFolk) {
              this.$toast.success("Xóa dân ca thành công");
              closeModal("deleteFolkModal");
              this.$router.push({name: "AdminFolkList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchComposers(q) {
      let query = `query($q: Mixed) {
        composers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.composers && data.data.composers.data) {
              return data.data.composers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchRecomposers(q) {
      let query = `query($q: Mixed) {
        recomposers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.recomposers && data.data.recomposers.data) {
              return data.data.recomposers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchMelodies(q) {
      let query = `query($q: Mixed) {
        melodies(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.melodies && data.data.melodies.data) {
              return data.data.melodies.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchFcats(q) {
      let query = `query($q: Mixed) {
        fcats(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.fcats && data.data.fcats.data) {
              return data.data.fcats.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    handleUploadDone(data) {
      if (data && data.type) {
        this.folk.file_type = data.type;
        this.folk.file.type = data.type;
      }
    }
  },
  computed: {
    canManageSong() {
      return canManageSong();
    }
  },
  mounted() {
    if (!canManageSong()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Sửa dân ca");
    this.id = this.$route.params.id;
    this.loadFolk();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadFolk();
    window.scrollTo(0, 0);
    next();
  },
}
</script>
